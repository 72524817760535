import { Grid, Step, StepLabel, Stepper } from '@material-ui/core'
import { useTranslation } from 'react-i18next'
import { useEffect, useState } from 'react'
import { ProductAplication } from './productApplcation'
import { PatientProduct } from '../../modules/patient/models/relationship/PatientProduct'
import { PatientAplication } from './patientApplication'
import { FileApplication } from './fileApplication'
import { File } from '../../modules/files/models/File'
import { SampleAplication } from './sampleApplication'
import { Address } from '../../modules/address/models/Address'
import {
  emptyPatientProductDTO,
  PatientProductDTO,
} from '../../modules/patient/models/relationship/PatientProductDTO'
import { navigate } from '@reach/router'
import { URL_APPLICATIONS } from '../../routes/routes-constants'
import { getClient2RequestContainer } from '../../container/client2request-modules'
import { Client2RequestService } from '../../modules/client2Requests/services/Client2RequestService'
import { CLIENT2REQUEST_SERVICE_KEY } from '../../modules/client2Requests'
import { getAuthContainer } from '../../container/auth-modules'
import { AuthService } from '../../modules/auth/services/AuthService'
import { AUTH_SERVICE_KEY } from '../../modules/auth'
import { LoadingSpinner } from '../../components/loading-spinner/LoadingSpinner'
import style from '../../components/loading-spinner/LoadingSpinner.module.css'
import { Query, QueryParam } from '../../common/api/Query'
import { getGenesContainer } from 'container/genes-modules'
import { GenesService } from 'modules/genes/services/GenesService'
import { GENES_SERVICE_KEY } from 'modules/genes'
import { getSampleTypeContainer } from 'container/sampleType-modules'
import { SampleTypeService } from 'modules/sampleType/services/SampleTypeService'
import { SAMPLETYPE_SERVICE_KEY } from 'modules/sampleType'
import { FormType } from 'common/enums/Enums'
import { useSnackbar } from 'notistack'

type DetailProps = {
  id?: string
  saveDraft?: boolean
  setSaveDraft: (b: boolean) => void
}

const steps = ['product', 'form', 'samples']
const clientRequestService = getClient2RequestContainer().get<Client2RequestService>(
  CLIENT2REQUEST_SERVICE_KEY
)
const authService = getAuthContainer().get<AuthService>(AUTH_SERVICE_KEY)
const genesService = getGenesContainer().get<GenesService>(GENES_SERVICE_KEY)
const sampleTypeService = getSampleTypeContainer().get<SampleTypeService>(SAMPLETYPE_SERVICE_KEY)

export const NewApplication = (props: DetailProps) => {
  const { t } = useTranslation()
  const [currentStep, setCurrentStep] = useState<number>(0)
  const [patientProducts, setPatientProducts] = useState<PatientProduct[]>([])
  const [files, setFiles] = useState<File[]>([])
  const [address, setAddress] = useState<Address>()
  const loggedUser = authService.get()
  const [isLoading, setIsLoading] = useState<boolean>(false)
  const [isReturn, setIsReturn] = useState<boolean>(false)
  const [userIDDoingTheRequest, setUserIDDoingTheRequest] = useState<string>()
  const [clientIDDoingTheRequest, setClientIDDoingTheRequest] = useState<string>()
  const { enqueueSnackbar } = useSnackbar()

  const handleChangeFiles = (fs: File[]) => {
    setFiles(fs)
  }

  useEffect(() => {
    if (props.saveDraft) {
      checkRequiredFields()
    }
  }, [props.saveDraft])

  const checkRequiredFields = () => {
    let completed = true

    patientProducts.forEach((p) => {
      if (p._product) {
        switch (Number(p._product.typeForm)) {
          case FormType.TypeFormMicroVEBioER:
            p._patientMicroVE.forEach((patient, i) => {
              if (i == 0) {
                if (patient._sample?._question1) {
                  if (!patient._sample?._endDate) {
                    patient._sample._endDate = new Date()
                  }
                  if (
                    !patient._sample?._typeAntibioticTreatment ||
                    !patient._sample?._treatmentDuration ||
                    !patient._sample?._endDate
                  ) {
                    completed = false
                    enqueueSnackbar(
                      t('mustCompleteTheFields') +
                        t('typeAntibioticTreatment') +
                        ', ' +
                        t('treatmentDuration') +
                        ', ' +
                        t('endDate'),
                      {
                        variant: 'error',
                      }
                    )
                  }
                }

                if (i == 0) {
                  if (!patient._sample?._typeSample) {
                    completed = false
                    enqueueSnackbar(t('mustCompleteTheField') + t('typeSample'), {
                      variant: 'error',
                    })
                  }
                }

                if (patient._sample?._question2) {
                  if (!patient._sample?._reportDate) {
                    patient._sample._reportDate = new Date()
                  }
                  if (
                    !patient._sample?._reportDate ||
                    patient._sample?._result == '' ||
                    patient._sample?._followedTreatment == ''
                  ) {
                    completed = false
                    enqueueSnackbar(
                      t('mustCompleteTheFields') +
                        t('reportDate') +
                        ', ' +
                        t('result') +
                        ', ' +
                        t('followedTreatment'),
                      {
                        variant: 'error',
                      }
                    )
                  }
                }
              }
            })
            if (!patientProducts[0].language && !patientProducts[0]._language) {
              completed = false
              enqueueSnackbar(t('mustCompleteTheField') + t('language'), {
                variant: 'error',
              })
            }
            break

          case FormType.TypeFormPGT:
            p._patientPGTs.forEach((patient, i) => {
              if (i == 0) {
                if (!patient._sample?._typeSample) {
                  completed = false
                  enqueueSnackbar(t('mustCompleteTheField') + t('typeSample'), {
                    variant: 'error',
                  })
                }
              }
            })
            if (!patientProducts[0].language && !patientProducts[0]._language) {
              completed = false
              enqueueSnackbar(t('mustCompleteTheField') + t('language'), {
                variant: 'error',
              })
            }
            break

          case FormType.TypeFormOncology:
            p._patientOncology.forEach((patient, i) => {
              if (i == 0) {
                if (!patient._sample?._typeSample) {
                  completed = false
                  enqueueSnackbar(t('mustCompleteTheField') + t('typeSample'), {
                    variant: 'error',
                  })
                }
              }
            })
            if (!patientProducts[0].language && !patientProducts[0]._language) {
              completed = false
              enqueueSnackbar(t('mustCompleteTheField') + t('language'), {
                variant: 'error',
              })
            }
            break
          case FormType.TypeFormCitogeneticaArray:
            p._patientCitogenetics.forEach((patient, i) => {
              if (i == 0) {
                if (!patient._sample?._typeSample) {
                  completed = false
                  enqueueSnackbar(t('mustCompleteTheField') + t('typeSample'), {
                    variant: 'error',
                  })
                }
              }
            })
            if (!patientProducts[0].language && !patientProducts[0]._language) {
              completed = false
              enqueueSnackbar(t('mustCompleteTheField') + t('language'), {
                variant: 'error',
              })
            }
            break
          case FormType.TypeFormNGS:
            p._patientNGS.forEach((patient, i) => {
              if (i == 0) {
                if (!patient._sample?._typeSample) {
                  completed = false
                  enqueueSnackbar(t('mustCompleteTheField') + t('typeSample'), {
                    variant: 'error',
                  })
                }
              }
            })
            if (!patientProducts[0].language && !patientProducts[0]._language) {
              completed = false
              enqueueSnackbar(t('mustCompleteTheField') + t('language'), {
                variant: 'error',
              })
            }
            break
          case FormType.TypeFormNull:
            p._patientNulls.forEach((patient, i) => {
              if (i == 0) {
                if (!patient._sample?._type) {
                  completed = false
                  enqueueSnackbar(t('mustCompleteTheField') + t('typeSample'), {
                    variant: 'error',
                  })
                }
              }
            })
            if (!patientProducts[0].language && !patientProducts[0]._language) {
              completed = false
              enqueueSnackbar(t('mustCompleteTheField') + t('language'), {
                variant: 'error',
              })
            }
            break
          default:
            if (!patientProducts[0].language && !patientProducts[0]._language) {
              completed = false
              enqueueSnackbar(t('mustCompleteTheField') + t('language'), {
                variant: 'error',
              })
            }
            break
        }
      } else if (p.product) {
        switch (Number(p.product.typeForm)) {
          case FormType.TypeFormMicroVEBioER:
            p.patientMicroVE.forEach((patient, i) => {
              if (i == 0) {
                if (!patient._sample?._typeSample) {
                  completed = false
                  enqueueSnackbar(t('mustCompleteTheField') + t('typeSample'), {
                    variant: 'error',
                  })
                }

                if (patient._sample?._question1) {
                  if (
                    !patient._sample?._typeAntibioticTreatment ||
                    !patient._sample?._treatmentDuration ||
                    !patient._sample?._endDate
                  ) {
                    completed = false
                    enqueueSnackbar(
                      t('mustCompleteTheFields') +
                        t('typeAntibioticTreatment') +
                        ', ' +
                        t('treatmentDuration') +
                        ', ' +
                        t('endDate'),
                      {
                        variant: 'error',
                      }
                    )
                  }
                }

                if (patient._sample?._question2) {
                  if (
                    !patient._sample?._reportDate ||
                    !patient._sample?._result ||
                    !patient._sample?._followedTreatment
                  ) {
                    completed = false
                    enqueueSnackbar(
                      t('mustCompleteTheFields') +
                        t('reportDate') +
                        ', ' +
                        t('result') +
                        ', ' +
                        t('followedTreatment'),
                      {
                        variant: 'error',
                      }
                    )
                  }
                }
              }
            })

            if (!patientProducts[0].language && !patientProducts[0]._language) {
              completed = false
              enqueueSnackbar(t('mustCompleteTheField') + t('language'), {
                variant: 'error',
              })
            }
            break

          case FormType.TypeFormPGT:
            p.patientPGTs.forEach((patient, i) => {
              if (i == 0) {
                if (!patient._sample?._typeSample) {
                  completed = false
                  enqueueSnackbar(t('mustCompleteTheField') + t('typeSample'), {
                    variant: 'error',
                  })
                }
              }
            })
            if (!patientProducts[0].language && !patientProducts[0]._language) {
              completed = false
              enqueueSnackbar(t('mustCompleteTheField') + t('language'), {
                variant: 'error',
              })
            }
            break

          case FormType.TypeFormOncology:
            p.patientOncology.forEach((patient, i) => {
              if (i == 0) {
                if (!patient._sample?._typeSample) {
                  completed = false
                  enqueueSnackbar(t('mustCompleteTheField') + t('typeSample'), {
                    variant: 'error',
                  })
                }
              }
            })
            if (!patientProducts[0].language && !patientProducts[0]._language) {
              completed = false
              enqueueSnackbar(t('mustCompleteTheField') + t('language'), {
                variant: 'error',
              })
            }
            break

          case FormType.TypeFormCitogeneticaArray:
            p.patientCitogenetics.forEach((patient, i) => {
              if (i == 0) {
                if (!patient._sample?._typeSample) {
                  completed = false
                  enqueueSnackbar(t('mustCompleteTheField') + t('typeSample'), {
                    variant: 'error',
                  })
                }
              }
            })

            if (!patientProducts[0].language && !patientProducts[0]._language) {
              completed = false
              enqueueSnackbar(t('mustCompleteTheField') + t('language'), {
                variant: 'error',
              })
            }
            break

          case FormType.TypeFormNGS:
            p.patientNGS.forEach((patient, i) => {
              if (i == 0) {
                if (!patient._sample?._typeSample) {
                  completed = false
                  enqueueSnackbar(t('mustCompleteTheField') + t('typeSample'), {
                    variant: 'error',
                  })
                }
              }
            })

            if (!patientProducts[0].language && !patientProducts[0]._language) {
              completed = false
              enqueueSnackbar(t('mustCompleteTheField') + t('language'), {
                variant: 'error',
              })
            }
            break
          case FormType.TypeFormNull:
            p.patientNulls.forEach((patient, i) => {
              if (i == 0) {
                if (!patient._sample?._type) {
                  completed = false
                  enqueueSnackbar(t('mustCompleteTheField') + t('typeSample'), {
                    variant: 'error',
                  })
                }
              }
            })

            if (!patientProducts[0].language && !patientProducts[0]._language) {
              completed = false
              enqueueSnackbar(t('mustCompleteTheField') + t('language'), {
                variant: 'error',
              })
            }
            break
          default:
            if (!patientProducts[0].language && !patientProducts[0]._language) {
              completed = false
              enqueueSnackbar(t('mustCompleteTheField') + t('language'), {
                variant: 'error',
              })
            }
            break
        }
      }
    })

    !completed && props.setSaveDraft(false)
    completed && handleSaveDraft()
  }
  
  const handleSaveDraft = async () => {
    setIsLoading(true)
    let arrToSend: PatientProductDTO[] = []

    let pdto = emptyPatientProductDTO()
    pdto.userID = userIDDoingTheRequest || loggedUser.id
    pdto.product = patientProducts[0]._product
    pdto.patientNulls = patientProducts[0].patientNulls
    pdto.patientPGTs = patientProducts[0].patientPGTs
    pdto.patientNGS = patientProducts[0].patientNGS
    pdto.patientMicroVE = patientProducts[0].patientMicroVE
    pdto.patientOncology = patientProducts[0].patientOncology
    pdto.patientCitogenetics = patientProducts[0].patientCitogenetics
    pdto.genes = patientProducts[0]._genes || patientProducts[0].genes
    console.log("fecha", patientProducts)
    pdto.address = patientProducts[0]._address
    //@ts-ignore
    pdto.file = props.files
    pdto.step = currentStep
    pdto.language = patientProducts[0].language || patientProducts[0]._language

    if (patientProducts[0]._genes?.length == 1) {
      let genes = await genesService
        .getFilteredList(
          new Query({
            query: [new QueryParam('names', patientProducts[0]._genes[0].name.split(','))],
          })
        )
        .toPromise()
      pdto.genes = genes?.items || []
    } else {
      pdto.genes = patientProducts[0]._genes
    }

    let types: string[] = []

    patientProducts.forEach((pp, i) => {
      if (i > 0) {
        pp.patientNulls?.forEach((p) => {
          types.push(p._sample?._type)
        })

        pp.patientPGTs?.forEach((p) => {
          types.push(p._sample?._typeSample)
        })

        pp.patientNGS?.forEach((p) => {
          types.push(p._sample?._typeSample)
        })

        pp.patientMicroVE?.forEach((p) => {
          types.push(p._sample?._typeSample)
        })

        pp.patientOncology?.forEach((p) => {
          types.push(p._sample?._typeSample)
        })

        pp.patientCitogenetics?.forEach((p) => {
          types.push(p._sample?._typeSample)
        })
      }
    })

    //@ts-ignore
    let uniqueArray = [...new Set(types)]

    if (uniqueArray?.length > 0) {
      let objTypes = await sampleTypeService
        .getFilteredListDB(
          new Query({
            query: [new QueryParam('idsOdoo', uniqueArray)],
          })
        )
        .toPromise()

      let indication = 'También llega muestra '

      objTypes?.items?.forEach((t, i) => {
        if (i > 0) {
          indication += ', '
        }
        indication += t.nameES
      })

      pdto.indications = indication
    }
    arrToSend.push(pdto)

    for (let i = 1; i < patientProducts?.length; i++) {
      let pdto = emptyPatientProductDTO()
      pdto.userID = userIDDoingTheRequest || loggedUser.id
      pdto.product = patientProducts[i]._product
      pdto.patientNulls = patientProducts[i].patientNulls
      pdto.patientPGTs = patientProducts[i].patientPGTs
      pdto.patientNGS = patientProducts[i].patientNGS
      pdto.patientMicroVE = patientProducts[i].patientMicroVE
      pdto.patientOncology = patientProducts[i].patientOncology
      pdto.patientCitogenetics = patientProducts[i].patientCitogenetics
      pdto.genes = patientProducts[i]._genes
      console.log("fecha", patientProducts[i]._address)
      pdto.address = patientProducts[i]._address
     
      //@ts-ignore
      pdto.file = files
      pdto.step = currentStep
      arrToSend.push(pdto)
    }

    clientRequestService.sendPatientProductDraft(arrToSend).subscribe((res) => {
      setIsLoading(false)
      navigate(URL_APPLICATIONS)
    })
  }

  const increaseStep = () => {
    setCurrentStep(currentStep + 1)
    setIsReturn(false)
  }
  const decreseStep = () => {
    setCurrentStep(currentStep - 1)
    setIsReturn(true)
  }

  return (
    <Grid container spacing={2} style={{ marginBottom: '4%' }}>
      <Grid item xs={12}>
        <Stepper activeStep={currentStep}>
          {steps.map((label) => (
            <Step key={label}>
              <StepLabel>{t(label)}</StepLabel>
            </Step>
          ))}
        </Stepper>
      </Grid>

      {isLoading ? (
        <LoadingSpinner className={style.loadingSpinner} />
      ) : (
        <>
          {currentStep === 0 && (
            <ProductAplication
              increaseStep={increaseStep}
              patientProducts={patientProducts}
              setPatientProducts={setPatientProducts}
              selectedProducts={patientProducts.map((p) => p.product)}
              handleChangeUserDoingRequest={(iduser, idClient) => {
                if (!iduser && !idClient) {
                  setUserIDDoingTheRequest(loggedUser.id)
                  setClientIDDoingTheRequest(loggedUser.clinicID)
                }
                setUserIDDoingTheRequest(iduser)
                setClientIDDoingTheRequest(idClient)
              }}
              edit={true}
              selectedClient={
                clientIDDoingTheRequest != loggedUser.clinicID ? clientIDDoingTheRequest : ''
              }
            />
          )}

          {currentStep === 1 && (
            <>
              <PatientAplication
                decreseStep={decreseStep}
                increaseStep={increaseStep}
                patientProducts={patientProducts}
                setPatientProducts={setPatientProducts}
                isReturned={isReturn}
                clientIDDoingTheRequest={clientIDDoingTheRequest || loggedUser.clinicID}
              />
              <FileApplication data={files} handleFile={(f) => handleChangeFiles(f)} />
            </>
          )}

          {currentStep === 2 && (
            <>
              <SampleAplication
                decreseStep={decreseStep}
                increaseStep={increaseStep}
                patientProducts={patientProducts}
                setPatientProducts={setPatientProducts}
                isReturned={isReturn}
                pickUp={address}
                files={files}
                userDoingTheRequest={userIDDoingTheRequest || loggedUser.id}
              />
            </>
          )}
        </>
      )}
    </Grid>
  )
}
