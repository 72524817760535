import { RouteProps } from '../../../routes/AppRouter'
import { Product } from '../../../modules/product/models/Product'
import { emptyPatientDTO, fromModel, PatientDTO } from '../../../modules/patient/models/PatientDTO'
import { useEffect, useState } from 'react'
import { useStyles } from '../Application.styles'
import { COLOR_PRIMARY } from '../../../routes/color-constants'
import { useTranslation } from 'react-i18next'
import { Patient } from '../../../modules/patient/models/Patient'
import { Grid, TextField, Select, MenuItem, FormControl, InputLabel, Button } from '@material-ui/core'
import { FormType } from '../../../common/enums/Enums'
import { PatientService } from 'modules/patient/services/PatientService'
import { PATIENT_SERVICE_KEY } from 'modules/patient'
import { getPatientContainer } from 'container/patient-modules'
import { Query, QueryParam } from 'common/api/Query'
import { getClientContainer } from 'container/client-modules'
import { ClientService } from 'modules/clients/services/ClientService'
import { CLIENTS_SERVICE_KEY } from 'modules/clients'
import { v4 as uuidv4 } from 'uuid'
import { emptySampleDTO, toModel } from 'modules/sample/models/SampleDTO'

export type FormNullProps = RouteProps & {
  patients: PatientDTO[]
  productIndex: number
  handleChangePatients: (productIndex: number, patients: any[], type: FormType) => void
  product: Product
  type?: string
  edit?: boolean
  clientIDDoingTheRequest: string
  setIsOpen: (bool: boolean) => void
  setCurrentProduct: (curentProduct: number) => void
  setCurentType: (curentType: number) => void
}

const patientService = getPatientContainer().get<PatientService>(PATIENT_SERVICE_KEY)
const clientService = getClientContainer().get<ClientService>(CLIENTS_SERVICE_KEY)

export const PatientFormNull = (props: FormNullProps) => {
  const patientNumber = props?.product?.patientNumber || 0
  const classes = useStyles({ color: COLOR_PRIMARY })
  const { t, i18n } = useTranslation()
  const [patients, setPatients] = useState<any[]>(props.patients)
  const [previousPatients, setPreviousPatients] = useState<Patient[]>([])
  const [selectedPatientID, setSelectedPatientID] = useState<any[]>([])

  useEffect(()=>{
    if (props.patients) setPatients(props.patients)
  },[props.patients])

  useEffect(() => {
    let patientsAux: PatientDTO[] = []
    let start = 0

    for (let i = start; i < patientNumber; i++) {
      if (props.patients[i]) {
        let filtered = previousPatients.filter((p) => p.id == props.patients[i]._id)
        let auxSelected: any[] = []

        if (filtered?.length > 0) {
          if (patientNumber > 0) {
            auxSelected[0] = filtered[0].id
            patientsAux[0] = fromModel(filtered[0])
          }

          setSelectedPatientID(auxSelected)
        }
        patientsAux[i] = props.patients[i]
      } else {
        patientsAux[i] = emptyPatientDTO()
      }
    }

    setPatients(patientsAux)
  }, [previousPatients])

  useEffect(() => {
    if (props.patients?.length > 0) {
      let auxSelected: any[] = []
      props.patients.forEach((p, i) => {
        auxSelected[i] = p._id
      })
      setSelectedPatientID(auxSelected)
    } else {
      let auxSelected: any[] = []
      Array(patientNumber).forEach((_, i) => {
        auxSelected[i] = ''
      })
      setSelectedPatientID(auxSelected)
    }
  }, [])

  useEffect(() => {
    clientService.getByID(props.clientIDDoingTheRequest).subscribe(async (res) => {
      if (res) {
        let client = res

        let patientsList = await patientService
          .getFilteredList(
            new Query({
              query: [new QueryParam('clientID', client.id || '')],
            })
          )
          .toPromise()

        let patients: any[] = patientsList?.items || []

        patients.forEach((i) => {
          i.priority = true
        })

        let citogenicPatientsItemList = await patientService
          .getFilteredListCitogenicArray(
            new Query({
              query: [new QueryParam('clientID', client.id || '')],
            })
          )
          .toPromise()

        let microvePatients = await patientService
          .getFilteredListMicroVE(
            new Query({
              query: [new QueryParam('clientID', client.id || '')],
            })
          )
          .toPromise()

        let ngsPatients = await patientService
          .getFilteredListNGS(
            new Query({
              query: [new QueryParam('clientID', client.id || '')],
            })
          )
          .toPromise()

        let oncologyPatients = await patientService
          .getFilteredListOncology(
            new Query({
              query: [new QueryParam('clientID', client.id || '')],
            })
          )
          .toPromise()

        let pgtPatients = await patientService
          .getFilteredListPGT(
            new Query({
              query: [new QueryParam('clientID', client.id || '')],
            })
          )
          .toPromise()

        const resultArray = [
          ...patients,
          ...(citogenicPatientsItemList?.items || []),
          ...(microvePatients?.items || []),
          ...(ngsPatients?.items || []),
          ...(oncologyPatients?.items || []),
          ...(pgtPatients?.items || []),
        ]

        let personasFiltradas: any[] = filtrarPersonas(resultArray)

        if (window.location.pathname.includes('new')) {
          personasFiltradas.forEach((persona) => {
            //@ts-ignore
            persona._sample = toModel(emptySampleDTO())
            //@ts-ignore
            persona.sample = toModel(emptySampleDTO())
          })
        }

        setPreviousPatients(personasFiltradas)
      }
    })
  }, [])

  function filtrarPersonas(personas: any[]): any[] {
    const personasFiltradas: { [key: string]: any } = {}

    personas.forEach((persona) => {
      if (personasFiltradas[persona.firstName + persona.lastName]) {
        if (persona.priority) {
          personasFiltradas[persona.firstName + persona.lastName] = persona
        }
      } else {
        personasFiltradas[persona.firstName + persona.lastName] = persona
      }
    })

    return Object.values(personasFiltradas)
  }

  const getEmptyPatients = (): PatientDTO[] => {
    const patients: PatientDTO[] = []
    if (patientNumber) {
      for (let i = 0; i < patientNumber; i++) {
        patients.push(emptyPatientDTO())
      }
    }
    return patients
  }

  useEffect(() => {
    if (
      props.type != 'view' &&
      ((props.patients !== null && props.patients?.length == 0) || props.patients == null)
    ) {
      props.handleChangePatients(props.productIndex, getEmptyPatients(), FormType.TypeFormNull)
    }
  }, [props.product])

  useEffect(() => {
    if (props.type == 'view') {
      props.handleChangePatients(props.productIndex, props.patients, FormType.TypeFormNull)
    }
  }, [props.type])

  const handlePatientChange = (index: number, field: keyof Patient, value: string) => {
    const newPatients = [...patients]
    const patientToUpdate = newPatients[index]
    const updatedPatient = { ...patientToUpdate, [field]: value }

    if (!updatedPatient._id) {
      updatedPatient._id = uuidv4()
    }

    newPatients[index] = updatedPatient

    setPatients(newPatients)
    props.handleChangePatients(props.productIndex, newPatients, FormType.TypeFormNull)
  }

  const handleSetSelectedValue = (index: number, value: string) => {
    let patientsAux = previousPatients.filter((p) => p.id == value)
    const newPatients = [...patients]

    if (patientsAux?.length > 0) {
      let patient = patientsAux[0]

      let auxElement = {
        _id: patient.id,
        _creationDate: patient.creationDate,
        _firstName: patient.firstName,
        _geneticDiseaseHistory: patient.geneticDiseaseHistory,
        _lastName: patient.lastName,
        _sample: patient._sample ? patient._sample : toModel(emptySampleDTO()),
      }

      newPatients.splice(index, 1, auxElement)
    } else {
      newPatients.splice(index, 1, {
        _id: uuidv4(),
        _firstName: '',
        _lastName: '',
        _geneticDiseaseHistory: '',
        _creationDate: new Date(),
        _sample: toModel(emptySampleDTO()),
        id: undefined,
        firstName: '',
        lastName: '',
        creationDate: new Date(),
        geneticDiseaseHistory: '',
        sample: toModel(emptySampleDTO()),
      })
    }

    setPatients(newPatients)
    props.handleChangePatients(props.productIndex, newPatients, FormType.TypeFormNull)

    let auxSelected = [...selectedPatientID]
    auxSelected[index] = value
    setSelectedPatientID(auxSelected)
  }

  const openModal = () => {
    props.setCurrentProduct(props.productIndex)
    props.setCurentType(FormType.TypeFormNull)
    props.setIsOpen(true)
  }

  return (
    <Grid container direction="column">
      {[...Array(patientNumber)].map((_, index) => (
        <>
          <Grid container spacing={4} key={index} style={{ marginTop: '40px' }}>
            <Grid item xs={12} style={{ textAlign: 'left', fontWeight: 'bold', fontSize: '18px' }}>
            {t('fillPatientData') + ' ' + (index + 1) + ' ' + t('for') + ' ' + (i18n.language == "es" ? props.product.nameES : props.product.nameEN)}
            </Grid>
            {props.edit !== false && (
              <Grid item xs={12}>
                <FormControl variant="outlined" fullWidth>
                  <InputLabel id="typeSampleLabel">{t('selectPatient')}</InputLabel>
                  <Select
                    labelId={'typeSampleLabel'}
                    id={'type'}
                    name={'type'}
                    //@ts-ignore
                    onChange={(e) => handleSetSelectedValue(index, e.target.value)}
                    style={{ width: '50%' }}
                    value={selectedPatientID?.length > index ? selectedPatientID[index] : ''}>
                    {previousPatients.map((patient, indexST) => {
                      return (
                        <MenuItem key={indexST} value={patient.id}>
                          {patient.firstName + ' ' + patient.lastName}
                        </MenuItem>
                      )
                    })}
                    <MenuItem key={''} value={''}>
                      {t('notSelect')}
                    </MenuItem>
                  </Select>


                {props.productIndex > 0 && (
                <Button
                  variant="contained"
                  onClick={openModal}
                  className={classes.button}
                  style={{ display: 'flex', alignSelf: 'flex-end' }}>
                  {t("aplyPatientes")}
                </Button>)
               }
               
                </FormControl>
              </Grid>
            )}
            <Grid item xs={6}>
              <TextField
                id={`firstName-${index}`}
                label={t('name')}
                variant="filled"
                disabled={props.edit === false}
                fullWidth
                className={classes.textField}
                value={(patients && patients[index]?._firstName) || ''}
                onChange={(e) => handlePatientChange(index, '_firstName', e.target.value)}
              />
            </Grid>
            <Grid item xs={6}>
              <TextField
                id={`lastName-${index}`}
                disabled={props.edit === false}
                label={t('lastName')}
                variant="filled"
                fullWidth
                className={classes.textField}
                value={(patients && patients[index]?._lastName) || ''}
                onChange={(e) => handlePatientChange(index, '_lastName', e.target.value)}
              />
            </Grid>
            <Grid item xs={12}>
              <TextField
                fullWidth
                id={`geneticDiseaseHistory-${index}`}
                label={t('geneticDiseaseHistory')}
                disabled={props.edit === false}
                onChange={(event) =>
                  handlePatientChange(index, '_geneticDiseaseHistory', event.target.value)
                }
                multiline
                rows={4}
                value={(patients && patients[index]?._geneticDiseaseHistory) || ''}
                variant="outlined"
                InputLabelProps={{
                  shrink: true,
                }}
              />
            </Grid>
          </Grid>
        </>
      ))}
    </Grid>
  )
}
