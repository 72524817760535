import { Box, Button, Grid } from '@material-ui/core'
import { useStyles } from "./Application.styles";
import { COLOR_PRIMARY } from "../../routes/color-constants";
import { useTranslation } from "react-i18next";
import { FormType } from 'common/enums/Enums';
import { PatientsTable } from 'features/patients';

import { emptySampleMicroVEBioERDTO, toModel as toModelMicroVE } from 'modules/sampleType/models/sampleForm/SampleMicroVEBioERDTO'
import { emptySamplePGTDTO, toModel as toModelPGT } from 'modules/sampleType/models/sampleForm/SamplePGTDTO'
import { emptySampleNGSDTO, toModel } from 'modules/sampleType/models/sampleForm/SampleNGSDTO'
import { emptySampleDTO , toModel as toModelNull} from 'modules/sample/models/SampleDTO'
import { emptySampleOncologyDTO, toModel as toModelOncology } from 'modules/sampleType/models/sampleForm/SampleOncologyDTO'
import { emptySampleCitogeneticaDTO } from 'modules/sampleType/models/sampleForm/SampleCitogeneticaDTO'
import { PatientPGTDTO } from 'modules/patient/models/patientForm/PatientPGTDTO'
import { PatientProduct } from 'modules/patient/models/relationship/PatientProduct';
import { useEffect, useState } from 'react';
import { PatientMicroVE } from 'modules/patient/models/patientForm/PatientMicroVE';
import { SampleCitogenetica } from 'modules/sampleType/models/sampleForm/SampleCitogenetica';
import { convertTimeToLocal } from 'common/utils/DataFormatters';

type ConsentProps = {
    handleModalClose: () => void
    handleChangePatients: (productIndex: number, patients: any[], type: FormType) => void
    patientProducts: PatientProduct[]
    currentProduct: number 
    type: FormType
}

export const ModalCopyPatients = (props: ConsentProps) => {
    const classes = useStyles({ color: COLOR_PRIMARY })
    const { t } = useTranslation()
    const [patients,setPatients] = useState<any[]>([])

    useEffect(()=>{
        var auxData: any[] = []
        

        props.patientProducts.map(product => {
            var aux: any[] = []

            if (product.patientMicroVE.length >= 1) aux = product.patientMicroVE
            else if (product.patientPGTs.length >= 1)aux = product.patientPGTs
            else if (product.patientNGS.length >= 1) aux = product.patientNGS
            else if (product.patientOncology.length >= 1) aux = product.patientOncology
            else if (product.patientCitogenetics.length >= 1) aux = product.patientCitogenetics
            else aux = product.patientNulls
            console.log("aux",aux)
            console.log("Auxdata",auxData)

            aux.forEach(data => {
              if (!auxData.some((item) => data._id === item._id)) auxData.push(data)
            })



        })

        setPatients(auxData)


    },[props.patientProducts])

    console.log("dentro del modal array",props.patientProducts)

    
    const aplyPatients = (indPatient: number, currentProduct: number, type: FormType) => {
      var aux: any = patients[indPatient]
      var patientsData: any[]
      var auxPatients: any[] = []
      

        switch (type) {
          case FormType.TypeFormMicroVEBioER: 
            if (aux != null) {
              let patient = aux
        
              let auxElement = {
                _id: patient._id,
                _firstName: patient._firstName,
                _lastName: patient._lastName,
                _age: patient._age,
                _dob: patient._dob,
                _phone: patient._phone,
                _email: patient._email,
                _ivfFailure: patient._ivfFailure,
                _abortions: patient._abortions,
                _nhc: patient._nhc,
                _sample: patient._sample ? patient._sample : toModelMicroVE(emptySampleMicroVEBioERDTO()),
              }
              auxPatients.push(auxElement)
            }
            patientsData = auxPatients
            break
          case FormType.TypeFormPGT:
            if (aux != null) {
              let patient = aux
        
              let auxElement = {
                _id: patient._id,
                _firstName: patient._firstName,
                _lastName: patient._lastName,
                _dob: patient._dob,
                _nhcOrDni: patient._nhcOrDni,
                _karyotype: patient._karyotype,
                _indication: patient._indication,
                _clinicHistory: patient._clinicHistory,
                _sample: patient._sample ? patient._sample : toModel(emptySampleNGSDTO()),
              }
              auxPatients.push(auxElement)
            }
            patientsData = auxPatients
            break
          case FormType.TypeFormNGS: 
            if (aux != null) {
              let patient = aux
        
              let auxElement = {
                _id: patient._id,
                _firstName: patient._firstName,
                _lastName: patient._lastName,
                _dob: patient._dob,
                _nhc: patient._nhc,
                _nss: patient._nss,
                _province: patient._province,
                _zipCode: patient._zipCode,
                _phone: patient._phone,
                _email: patient._email,
                _indication: patient._indication,
                _clinicHistory: patient._clinicHistory,
                _sex: patient._sex,
                _sample: patient._sample ? patient._sample : toModel(emptySampleNGSDTO()),
              }
              auxPatients.push(auxElement)
            }
            patientsData = auxPatients
            break
          case FormType.TypeFormOncology:
            if (aux != null) {
              let patient = aux
        
              let auxElement = {
                _id: patient._id,
                _firstName: patient._firstName,
                _lastName: patient._lastName,
                _dob: patient._dob,
                _sex: patient._sex,
                _street: patient._street,
                _city: patient._city,
                _zipCode: patient._zipCode,
                _country: patient._country,
                _phone: patient._phone,
                _diagnosis: patient._diagnosis,
                _stage: patient._stage,
                _indication: patient._indication,
                _clinicHistory: patient._clinicHistory,
                _sample: patient._sample ? patient._sample : toModelOncology(emptySampleOncologyDTO())
              }
              auxPatients.push(auxElement)
            }
            patientsData = auxPatients
            break
          case FormType.TypeFormCitogeneticaArray: 
            if (aux != null) {
              let patient = aux
        
              let auxElement = {
                _id: patient._id,
                _firstName: patient._firstName,
                _lastName: patient._lastName,
                _dob: patient._dob,
                _nhc: patient._nhc,
                _sex: patient._sex?.toString(),
                _indication: patient._indication,
                _previousGeneticStudies: patient._previousGeneticStudies,
                _familyBackground: patient._familyBackground,
                _fetalSex: patient._fetalSex?.toString(),
                _eggDonationPregnancy: patient._eggDonationPregnancy,
                _weeksGestation: patient._weeksGestation,
                _sample: patient._sample
                  ? patient._sample
                  : patient._sample
                  ? patient._sample
                  : new SampleCitogenetica(emptySampleCitogeneticaDTO()),
              }
              auxPatients.push(auxElement)
            }
            patientsData = auxPatients
            break
          default: 
            if (aux != null) {
              let patient = aux
        
              let auxElement = {
                _id: patient._id,
                       _creationDate: patient._creationDate,
                       _firstName: patient._firstName,
                       _geneticDiseaseHistory: patient._geneticDiseaseHistory,
                       _lastName: patient._lastName,
                       _sample: patient._sample ? patient._sample : toModelNull(emptySampleDTO()),
              }
              auxPatients.push(auxElement)
            }
            patientsData = auxPatients
            
        }
         
        props.handleChangePatients(currentProduct, patientsData, type)
        props.handleModalClose()
      }




    return (
        <>


      <Grid className={classes.modalLanguage} container>
            <Grid xs={12} className={classes.titleSuccess}>
                {t("selectPatient")}
            </Grid>
            {patients.map((p, index )=>(


              (p._firstName !=="") &&
              <Button
              key={index}
                onClick={() => aplyPatients(index, props.currentProduct, props.type)}
                style={{
                  color: 'white',
                  cursor: 'pointer',
                  backgroundColor: '#d6d6d6',
                  width: '80%',
                  marginTop: '2%',
                  justifyContent: 'center',
                  display: 'flex',
                  borderRadius: '15px',
                  height: '40px',
                }}>
                <p style={{ fontSize: '15px', alignSelf: 'center', color:"black" }}>{p._firstName} {p._lastName}</p>
              </Button>
            ))}
            <div style={{ display: 'flex', flexDirection: "row", justifyContent: "space-around", marginBottom: "3%", width: "100%" }}>
                <Button className={classes.buttonModalCancel} type={"button"} onClick={props.handleModalClose}>
                    {t('skip')}
                </Button>
                {/* <Button className={classes.button} type={"button"} onClick={props.onPrint}>{t('print')}</Button> */}
            </div>
        </Grid>
            

        </>
    )
}